@import '_reset.scss';
@import 'bootstrap.scss';
@import 'ui-kit.scss';
@import 'var.scss';

* {
	box-sizing: border-box;
}

button {
	padding: 0;
	border: none;
	background: none;
}

img {
	width: 100%;
}

svg {
	width: 100%;
	height: auto;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}

html body {
	color: var(--font-color);

	font-family: 'Roboto', sans-serif;
	background: #ededed;
	font-size: 16px;
	line-height: 1.6;
	font-weight: 400;
}

button,
input,
a,
textarea {
	font-family: 'Roboto', sans-serif;
}

.header {
	display: flex;
	align-items: center;
	height: 64px;
	padding: 0 12px;
	background: #00da83;
	&__logo {
		height: 48px;
		width: 48px;
		align-items: center;
		justify-content: center;
		background: #3b3d48;
		border-radius: 50%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}
}

.mob-nav-btn {
	display: none;
	margin-right: 15px;
	width: 16px;
}

.authorization-section {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 64px);
	padding: 66px 0 66px 0;
}

.authorization-form {
	width: 352px;
	max-width: calc(100% - 20px);
	margin: 0 auto;
	padding: 24px 38px;
	background: #ffffff;
	border: 1px solid #e3e7ee;
	border-radius: 8px;
	&__logo {
		margin: 0 auto 16px auto;
		width: 50px;
	}
	&__body {
		margin-top: 18px;
	}
}

.form-title {
	font-weight: 700;
	font-size: 24px;
	text-align: center;
}

.form-submit {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	gap: 24px;
	&--start {
		justify-content: flex-start;
	}
	&--margin-none {
		margin-top: 0;
	}
}

.sidebar {
	width: 250px;
	flex: 0 0 250px;
	padding: 14px;
	background: #fff;
	transition: all 0.3s ease;
	&__action {
		position: relative;
		display: flex;
		flex: 0 0 24px;
		max-width: 24px;
		margin: 0 0 0 auto;
		z-index: 2;
		.stroke path {
			stroke: #3b3d48;
		}
	}
}

.main-section {
	display: flex;
	min-height: calc(100vh - 64px);
	transition: all 0.3s ease;
	&.minimized {
		.sidebar {
			width: 50px;
			flex: 0 0 50px;
			padding: 12px 0;
			transition: width 0.3s ease;
			&__action {
				margin: 0 auto;
				transform: rotate(180deg);
			}
			@media screen and (max-width: 991px) {
				flex: 0 0 100%;
			}
		}
		.sidebar-nav__subitem {
			display: none;
		}
		.sidebar-nav__list {
			> li {
				> a,
				> button {
					justify-content: center;
					padding: 12px 0;
					font-size: 0;
				}
			}
		}
		.sidebar-nav__icon {
			margin: 0;
		}
		.main-content {
			width: calc(100% - 50px);
		}
	}
}

.main-content {
	width: calc(100% - 250px);
	padding: 30px 12px;
	transition: width 0.3s ease;
	&--flex {
		display: flex;
		flex-direction: column;
	}
	@media screen and (max-width: 991px) {
		padding: 16px 12px;
	}
}

.sidebar-icon {
	&--svg {
		flex: 0 0 14px;
		min-width: 14px;
	}
	&--stroke {
		svg path {
			stroke: #777e90;
		}
	}
}

.sidebar-nav {
	margin-top: 15px;

	&__icon {
		display: flex;
		flex: 0 0 18px;
		max-width: 18px;
		margin: 0 10px 0 0;
		svg {
			max-height: 18px;
			stroke: #777e91;
		}
	}
	&__subitem {
		padding: 13px 12px 13px 47px;

		&--active {
			background: #03bc70;
			border-radius: 4px;
			.sidebar-nav__sublink {
				font-weight: bold;
				color: #fff;
			}
			svg {
				stroke: #fff;
			}
		}
	}
	&__sublink {
		color: #778192;
		font-size: 15px;
		line-height: 22px;

		&:hover {
			font-weight: bold;
		}
	}
	&__list {
		> li {
			> a,
			> button {
				display: flex;
				align-items: center;
				padding: 12px 18px;
				border-radius: 4px;
				font-size: 15px;
				color: var(--font-color-third);
				transition: all 0.3s ease;

				&.active,
				&:hover {
					background: #03bc70;
					color: #fff;
					font-weight: 500;
					.sidebar-icon::before {
						color: #fff;
					}
					.sidebar-icon--stroke {
						svg path {
							stroke: #fff;
						}
					}
					.sidebar-nav__icon {
						svg {
							stroke: #fff;
						}
					}
				}
			}
		}
	}
}

.title {
	font-size: 29px;
	line-height: 1.4;
	@media screen and (max-width: 767px) {
		font-size: 24px;
	}
}

.title-block {
	display: flex;
	align-items: center;
	&__icon {
		display: flex;
		align-items: center;
		width: 34px;
		margin-right: 12px;
		svg {
			width: 100%;
			height: auto;
		}
	}
}

.count-label {
	margin: 8px 0;
	font-size: 14px;
}

.table-block {
	margin-top: 16px;
	background: #fff;
	border: 1px solid #e6e8ec;
	border-radius: 10px;

	&--walletman {
		margin-top: 0;
		border: none;
	}
	&--nomt {
		margin-top: 0;
	}
	&--trades {
		padding: 63px 15px 80px 15px;
		border-radius: 3px;
	}
}

@-moz-document url-prefix() {
  .table--with-scroll{
    scrollbar-color:  var(--main-color) #8f9bba;
    scrollbar-width: thin;
  }
  .select__drop-scroll {
    scrollbar-color:  var(--main-color) #8f9bba;
    scrollbar-width: thin;
  }
}

.table {
  border-radius: 10px;
  font-size: 14px;
  // &--transaction {
  //     background: #F9F9F9;
  //     & .tr {
  //         grid-template-columns: 0.5fr 335px 1fr;
  //     }
  // }

  &--user-management {    
    .tr {
      grid-template-columns: 0.6fr 0.9fr 0.9fr 0.7fr 1.4fr 0.9fr 0.9fr 100px 80px 0.9fr 0.9fr minmax(90px, 0.25fr) 60px 60px;
    }
    .table-body {
      min-height: 200px;
    }
    .table-header, .table-body {
      min-width: 1540px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
  }
  &--user-management-business {   
    .tr {
      //grid-template-columns: 0.6fr 0.9fr 0.8fr 0.9fr 0.7fr 1.45fr 0.9fr 0.9fr 100px 0.9fr 0.9fr minmax(90px, 0.25fr) 60px 60px;
      grid-template-columns: .6fr .9fr .8fr .9fr .7fr 1.5fr .9fr .9fr 90px .9fr minmax(100px,.7fr)minmax(100px,.7fr) 90px 60px 60px;
    }
    .table-body {
      min-height: 200px;
    }
    .table-header, .table-body {
      min-width: 1640px;     
    }
  }
  &--user-management, &--user-management-business {
    overflow: auto;
    .table-body {
      min-height: 200px;
    }
    .table-header, .table-body {      
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
  }
  &--with-scroll {
    //scrollbar-color: #E6E8EC var(--main-color);
    //scrollbar-width: thin;
    padding-right: 5px;    
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #E6E8EC;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--main-color);
    }
    .table-body {
      
      .tr:nth-last-child(1), .tr:nth-last-child(2) {
        .hint-item {
          top: unset;
          bottom: calc(100% + 10px);
          &:before {
            top: 100%;
            transform: rotate(180deg);
          }
        }
      }
      .tr:nth-child(1), .tr:nth-child(2) {
        .hint-item {
          top: calc(100% + 10px);
          bottom: unset;
          &:before {
            top: -9px;
            transform: none;
          }
        }
      }
    }
  }
  &--user-acc {
    & .tr {
      grid-template-columns:  0.8fr 1fr;
    }
  }
  &--tfa-requests-email {
    & .tr {
      grid-template-columns: 0.6fr 1.2fr 1fr 1fr 0.6fr 0.6fr minmax(120px, 0.5fr);
    }
  }
  &--tfa-requests-sms {
    & .tr {
      grid-template-columns: 0.6fr 1fr 1fr 1fr 0.6fr 0.6fr minmax(120px, 0.5fr);
    }
  }
  &--spot-balances {
    .tr-wrap {
      &+.tr-wrap {
        border-top: 1px solid $border-color;
        @media screen and (max-width: 1199px) {
          border-width: 2px;
        }
      }
    }
    & .tr {
      grid-template-columns:  1fr minmax(10px, 2.2fr) 1fr 0.9fr;
    }
  }
  &--tron-staking {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 0.8fr 1fr ;
    }
  }
  &--p2p-balances {
    & .tr {
      grid-template-columns:  0.9fr 1fr 0.9fr;
    }
  }
  &--transaction-history {
    & .tr {
      grid-template-columns: 2.4fr 1.8fr 1fr 1fr 1fr;
    }
  }
  &--transactions {
    & .tr {
      grid-template-columns: 1.8fr 2.4fr 1.3fr 1fr 1fr 105px;
    }
  }
  &--list-admin {
    & .tr {
      grid-template-columns: 1fr 1fr 1.2fr 2fr 1fr 1fr 0.7fr;
    }
  }
  &--medium {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &--wallet {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1.1fr 1.1fr 1.4fr 1.5fr;
    }
  }
  &--ip {
    & .tr {
      grid-template-columns: 1fr 0.8fr;
    }
  }
  &--login-his {
    & .tr {
      grid-template-columns: 80px 1fr 2fr 1fr 145px 170px 105px 85px;
    }
  }
  &--p2p-trades {
    & .tr {
      // grid-template-columns: 1fr 60px 50px 65px 65px 105px 150px 150px 165px 100px;
      grid-template-columns: 1fr 60px 50px 65px 65px 0.5fr 0.6fr 0.6fr 0.7fr 0.5fr;
    }
  }
  &--p2p-adv {
    & .tr {
      grid-template-columns: 1fr 1fr 50px 65px 1fr 1fr 1fr 1fr 1fr;
    }
  }
  &--deposit-his {
    & .tr {
      grid-template-columns: 0.5fr 0.5fr 0.4fr 0.5fr 1fr 1fr 1fr 120px;
    }
  }
  &--history-deposit-fiat {
    & .tr {
      grid-template-columns: 60px 0.8fr 60px minmax(170px, 1fr) minmax(60px,0.6fr) 1.3fr 1fr 0.7fr 1fr 0.6fr minmax(60px,0.6fr) minmax(70px,0.6fr) minmax(100px,0.7fr) 150px;
    }
    .table-header, .table-body {
      min-width: 1600px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
  }
  &--transaction-history-redemption {
    .tr {
      grid-template-columns: minmax(30px, 0.3fr)  1fr 0.7fr  1fr 0.8fr 0.5fr minmax(40px, 0.5fr) minmax(1px,1.1fr) minmax(100px,0.6fr);
    }
    .hint-item {  
      left: -100px;   
      &:before {
        left: 130px;
      }
      @media screen and (min-width: 991px) {
        
        &:before {
          left: 130px;
        }
      }      
    }
  }
  &--payment-bank-transfer {
    & .tr {
      grid-template-columns: 1fr 0.7fr 0.8fr 1.1fr 0.8fr 1.1fr 130px 60px ;
    }
  }
  &--payment-cards {
    & .tr {
      grid-template-columns: 1fr 1.2fr 120px;
    }
  }
  &--bank-account {
    & .tr {
      grid-template-columns: 0.9fr 0.8fr 0.8fr 0.8fr 1fr 1fr 80px 1fr 60px;
    }
  }
  &--withdraw {
    & .tr {
      grid-template-columns: 0.5fr 0.5fr 0.5fr 0.6fr 0.8fr 1fr 1.5fr 1.2fr 110px;
    }
  }
  &--transfer {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &--dispute {
    & .tr {
      grid-template-columns: 1fr 60px 50px 65px 65px 0.2fr 0.8fr 120px 120px;
    }
  }
  &--dispute2 {
    & .tr {
      grid-template-columns: 1.5fr minmax(65px, 0.4fr) minmax(65px, 0.4fr) minmax(
          65px,
          0.5fr
        ) minmax(65px, 0.5fr) minmax(65px, 0.5fr) minmax(170px, 1fr) 120px 100px;
    }
  }
  &--fee {
    & .tr {
      grid-template-columns: 0.5fr 0.7fr 1fr 0.6fr;
    }
  }
  &--fee-trade {
    & .tr {
      grid-template-columns: 0.5fr 0.7fr 0.7fr 1fr 1fr 0.6fr;
    }
  }
  &--new-fee {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 0.4fr;
    }
  }
  &--new-fee-crypto{
    & .tr {
      grid-template-columns: minmax(135px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
    }
  }
  &--new-fee-fiat {
    & .tr {
      grid-template-columns: 0.9fr 1fr 0.8fr 0.8fr minmax(1px,0.8fr) minmax(1px,0.8fr) minmax(1px, 1fr) minmax(1px,0.8fr) minmax(1px,1fr) minmax(1px,0.8fr) minmax(1px,1fr) minmax(1px,0.8fr) minmax(80px,0.4fr);
      &--editable {
        background: #CCF8E6;
        .td {
          &:nth-child(3),&:nth-child(5),&:nth-child(7), &:nth-child(9),&:nth-child(11) {
            background: transparent;
          }
        }
      }
    }
    .table-header {
      .td {
        display: flex;
        height: 100%;
        align-items: center;
        word-break: break-word;
      }
    }
    & .td {
      &:nth-child(3),&:nth-child(5),&:nth-child(7), &:nth-child(9),&:nth-child(11) {
        background: #FAFAFA;
        @media screen and (max-width: 1199px) {
         background: transparent;
        }
      }
    }
  }
  &--new-fee-trades {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.3fr;
    }
  }
  &--new-fee-volumes {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 0.9fr 0.9fr 1fr 1fr minmax(
          80px,
          0.2fr
        );
    }
  }
  &--new-fee-margin-borrow {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 0.3fr;
    }
  }
  &--new-fee-p2p {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 1fr 1fr 0.3fr;
    }
  }
  &--limitations {
    .table-header {
      .tr {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &--limitations-fiat-withdraw, &--limitations-fiat-deposit {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
    }
  }
  &--limitations-single-crypto, &--limitations-daily-crypto, &--limitations-monthly-crypto  {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 0.9fr 1fr 1fr  0.9fr 0.4fr;
    }
    & .td {
      &:nth-child(2),&:nth-child(4),&:nth-child(6) {
        background: #FAFAFA;
        @media screen and (max-width: 1199px) {
         background: transparent;
        }
      }
    }
  }
  &--limitations-daily-fiat, &--limitations-monthly-fiat {
    & .tr {
      grid-template-columns: 0.8fr 1fr 0.9fr 1fr 1fr 1fr 0.4fr;
    }
    & .td {
      &:nth-child(3),&:nth-child(5) {
        background: #FAFAFA;
        @media screen and (max-width: 1199px) {
         background: transparent;
        }
      }
    }
  }
  &--limitations-p2p {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.4fr;
    }
  }
  &--admin-managment {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 1fr 1.4fr 1fr 0.6fr;
    }
  }
  &--withdrawals {
    & .tr {
      grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1.5fr 2fr 0.6fr;
    }
  }
  &--withdrawals-pending {
    & .tr {
      grid-template-columns: 0.5fr 0.8fr 0.8fr 1fr 1fr 1fr 1.5fr 2fr 0.6fr;
    }
  }
  
  &--withdrawals-pending-fiat {
    & .tr {
      grid-template-columns: 0.5fr 0.6fr 0.7fr 0.7fr 1.5fr 0.9fr 1fr 1.5fr 2fr 0.5fr;
    }
  }

  &--pending-bank-transfer {
    .tr {
      grid-template-columns: 0.5fr 0.6fr 0.6fr 0.7fr 1.5fr 1fr 1fr 0.5fr 0.5fr;
    }
  }
  &--pending-gold {
    .tr {
      grid-template-columns: 50px 0.4fr minmax(120px,1fr) 1fr 1fr 1fr 0.4fr 1fr minmax(1px, 1fr) minmax(170px, 1fr) minmax(50px, 0.4fr);
    }
  }
  &--withdrawals-2 {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
  &--trading-pairs {
    & .tr {
      grid-template-columns: 1fr 0.5fr 1fr 1fr 1fr 1fr 1fr minmax(90px, 0.5fr) ;
    }
  }
  &--notification {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 0.2fr;
    }
  }
  &--unidentified-deposits{
    .tr {
        grid-template-columns: minmax(90px, 0.5fr) minmax(80px, 0.5fr)  minmax(70px, 0.4fr)  minmax(140px, 1.1fr) 1fr  minmax(140px, 1.1fr) minmax(70px, 0.6fr) minmax(60px, 0.5fr)  0.9fr 0.9fr 1.1fr minmax(150px, 1fr) minmax(150px, 1fr) minmax(70px, 0.4fr) minmax(110px, 0.6fr) minmax(160px, 0.6fr) minmax(60px, 0.5fr);

        &:nth-last-child(1), &:nth-last-child(2) {
            .hint-item {
                top: unset;
                bottom: calc(100% + 5px);
            }
        }
        &:only-child {
            .hint-item {
                top:calc(100% + 5px);
                bottom: unset;
            }
        }
    }    
    .table-header, .table-body {
        min-width: 1860px;
    }    
    .table-body {
        min-height: 260px;
    }
    .td:hover {
        z-index: 5;
    }
    .hint-item {
        width: 280px;
        left: -120px;
        font-size: 13px;
        &::before {
          left: 150px;
        }
    }

  }

  &--transaction {
    & .tr {
      grid-template-columns: minmax(70px, 0.4fr) minmax(80px, 0.8fr) minmax(
          110px,
          1.2fr
        ) 0.9fr 0.8fr 1fr 1fr 1fr 110px minmax(220px,1fr) 110px 120px;
    }
    .table-header, .table-body {
      min-width: 1500px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
  }
  &--transaction-withdraw-crypto {
    & .tr {
      grid-template-columns: minmax(70px, 0.4fr) minmax(80px, 0.8fr) minmax(
          110px,
          1.2fr
        ) 0.9fr 0.8fr 1fr 1fr 1fr 110px 110px 120px;
    }
   
  }
  &--transaction-fiat {
    & .tr {
      grid-template-columns: 0.5fr minmax(100px, 1.3fr) 1fr minmax(140px, 1.2fr) 1.1fr 1.1fr 1.1fr minmax(
          110px,
          1fr
        );
    }
  }
  &--transaction-dep-fiat {
    & .tr {
      grid-template-columns: 50px 0.7fr 50px 150px 75px 1fr minmax(60px, 0.6fr) 1.3fr 1.1fr 0.7fr 1.1fr minmax(60px,0.6fr)  minmax(60px,0.6fr) minmax(60px,0.6fr) minmax(220px,1fr) minmax(110px,0.7fr);
    }
    .table-header, .table-body {
      min-width: 1800px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
  }
  &--transaction-transfer {
    & .tr {
      grid-template-columns: 1fr 1.5fr 0.6fr 1fr 1fr 1fr 1fr;
    }
  }
  &--transaction-game {
    .tr {
      grid-template-columns: minmax(30px, 0.4fr) 0.6fr 0.8fr  minmax(140px, 1fr) minmax(40px, 0.5fr) 0.75fr 1fr minmax(1px,1.1fr) minmax(100px,0.8fr);
    }
  }
  &--transaction-redemption {
    .tr {
      grid-template-columns: minmax(30px, 0.4fr)  minmax(50px,0.5fr) 1fr 0.7fr  minmax(140px, 1fr) 0.8fr 0.5fr minmax(40px, 0.5fr) minmax(1px,1.1fr) minmax(100px,0.6fr);
    }
    .hint-item {     
      @media screen and (min-width: 991px) {
        left: -100px;
        &:before {
          left: 130px;
        }
      }      
    }
  }
  &--transaction-convert {
    & .tr {
      grid-template-columns: 0.8fr 1.3fr minmax(100px, 0.8fr) 0.6fr 0.6fr 1fr 1fr 1fr 1fr;
    }
  }
  &--transaction-referral {
    & .tr {
      grid-template-columns: 0.5fr 0.7fr 0.7fr  minmax(150px, 1.2fr) 0.8fr 1fr 1fr 0.8fr 0.8fr;
    }
  }
  &--pending-deposits {
    .tr {
      grid-template-columns: minmax(50px, 0.3fr) minmax(30px, 0.4fr) 0.6fr 0.8fr  minmax(140px, 1fr) minmax(40px, 0.8fr) 1fr 1fr minmax(70px,0.6fr);
    }
    @media screen and (max-width: 1199px) {
      .table-header {
        display: flex;
        .tr {
          width: 100%;
        }
        .td:not(:first-child) {
          display: none;
        }
      }
    }
  }
  &--market-maker {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.6fr;
    }
  }
  &--market-bot {
    .tr {
      grid-template-columns: 1.1fr 1.2fr  minmax(1px,1fr) minmax(1px,1fr) minmax(1px,1fr)  minmax(1px,1fr) minmax(125px,1fr)  minmax(120px,1fr) minmax(155px,1fr) minmax(60px,0.4fr) minmax(60px,0.5fr);
    }
    .table-input input {
      max-width: 100%;
    }
  }

  &--walletman {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr minmax(120px,0.8fr) minmax(140px,0.6fr) minmax(70px,0.5fr);
    }
  }
  &--wallet-new {
    & .tr {
      grid-template-columns: 0.8fr 1fr 1fr 1fr 0.7fr;
    }
  }
  &--wallett-transact {
    .table-body {
      min-height: 200px;
    }
    .table-header, .table-body {
      min-width: 1600px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
    & .tr {
      grid-template-columns: 0.5fr minmax(95px, 0.6fr) 60px minmax(80px, 0.5fr) minmax(85px, 0.6fr)  minmax(10px,2fr) 0.6fr 0.7fr 1.1fr 0.7fr;
      gap: 0 10px;
    }
  }
  &--wallet-bot-liquidity {
    .tr {
      grid-template-columns: 1fr 1fr 1fr 0.5fr;
    }
  }
  &--wallet-kraken-balance {
    .tr {
      grid-template-columns: 1fr 1.1fr 1fr 1fr 0.5fr;
    }
  }
  &--wallet-bank-balance {
    .tr {
      grid-template-columns: 1fr  1fr 0.5fr;
    }
  }
  &--wallet-external {
    .tr {
      grid-template-columns: 0.9fr minmax(150px, 0.9fr) minmax(100px, 0.4fr) minmax(
          150px,
          1fr
        ) 1fr 0.5fr minmax(70px, 0.2fr);
    }
  }
  &--wallet-external-history {
    .tr {
      grid-template-columns: minmax(90px, 0.6fr) minmax(100px, 0.6fr) minmax(
          100px,
          0.4fr
        ) minmax(60px, 0.4fr) minmax(80px, 0.5fr) minmax(90px, 1fr) minmax(
          90px,
          1fr
        ) minmax(90px, 1fr) minmax(155px, 1fr) minmax(96px, 0.2fr);
    }
  }
  &--p2p-limits {
    & .tr {
      grid-template-columns: 0.5fr 0.7fr 1fr 0.6fr;
    }
  }
  &--token-management-history {
    .tr {
      grid-template-columns: 0.7fr 1.5fr 0.95fr 0.95fr 0.95fr 1.15fr minmax(
          160px,
          1.2fr
        );
    }
  }
  &--spot-trades {
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    .table-header, .table-body {
      //min-width: 1340px;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
    }
    & .tr {
      grid-template-columns: minmax(140px, 0.9fr) minmax(70px, 0.65fr) minmax(70px, 0.65fr) minmax(90px, 0.8fr)  minmax(70px, 0.7fr) minmax(60px, 0.8fr) 0.7fr 0.7fr 0.8fr 0.6fr 1fr 1fr 1fr;
    }
  }
  &--margin-trades {
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    & .tr {
      //grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: minmax(160px, 1fr) 0.6fr minmax(120px, 0.7fr) 0.5fr 0.8fr 1fr 1fr 1fr;
    }
  }

  &--P2P-trades {
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    .table-body .tr {
      position: relative;
      padding-top: 30px;
    }
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &--spot-orders {
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    & .tr {
      grid-template-columns: 0.9fr 1fr minmax(100px, 1fr) minmax(50px, 0.6fr) 1fr 1fr 1fr 1fr minmax(100px, 1fr)  minmax(140px, 1fr) minmax(140px, 1fr);
    }
  }
  &--margin-orders {
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    & .tr {
      grid-template-columns: minmax(80px, 0.8fr) minmax(90px, 1fr) minmax(90px, 0.8fr) 1fr 1fr 1fr 1.2fr 0.8fr minmax(140px, 1fr) minmax(140px, 1fr);
    }
  }
  &--P2P-orders {
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    & .tr {
      grid-template-columns:minmax(80px, 0.9fr) 0.9fr 0.8fr 0.8fr minmax(80px, 1fr)  1fr 1fr 1fr 1fr minmax(140px, 1fr) minmax(140px, 1fr);
    }
    .td {
      word-break: break-all;
    }
  }

  &--pairs-margin-cross,
  &--pairs-margin-cross-trading {
    & .tr {
      grid-template-columns: 1fr 1fr 1fr 0.4fr;
    }
  }
  &--external-pairs {
    & .tr {
      grid-template-columns: 60px 1fr 1fr 1fr 1.3fr 1fr 1fr 100px 60px;
    }
  }
  &--external-history {
    & .tr {
      grid-template-columns: 70px minmax(145px, 1fr) minmax(90px, 0.8fr) minmax(90px, 0.8fr) minmax(90px, 0.8fr) minmax(50px, 0.4fr) 1fr 1fr 0.8fr 0.8fr minmax(55px, 0.4fr) 100px 1fr 1fr;
    }
  }
  &--referrals {
    & .tr {
      grid-template-columns: 80px 1fr 1.4fr 0.9fr 0.9fr 0.9fr 0.9fr;
    }
  }
  &--referral-user {
    & .tr {
      grid-template-columns: 70px 0.7fr minmax(40px, 0.3fr) 1.2fr minmax(70px, 0.5fr) minmax(105px, 0.5fr) minmax(150px, 0.9fr) 0.7fr 0.8fr 100px;
    }
  }
  &__block-title {
    display: block;
    padding: 18px 25px 10px 25px;
    font-size: 16px;
    line-height: 24px;
  }
}

.table-transaction {
	border: 1px solid #e6e8ec;
	border-radius: 4px;
	&__name {
		display: flex;
		align-items: center;
		padding: 16px;
	}
	&__icon {
		display: flex;
		align-items: center;
		width: 15px;
		height: 15px;
	}
	&__title {
		display: block;
		padding-left: 14px;
		color: var(--ui-main-color);
		font-size: 14px;
		line-height: 18px;
	}
}

.table-desc {
	display: block;
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	color: #9ea2a9;
}

.tr {
	display: grid;
	align-items: center;
}

.td {
	padding: 15px 8px;
	word-break: break-word;
	a {
		color: #71a2b0;
		text-decoration: underline;
	}
	&:first-child {
		padding-left: 12px;
	}
	&:last-child {
		padding-right: 12px;
	}
	&--right {
		text-align: right;
	}
	&--padding-left {
		padding-left: 25px;
	}
	&--red {
		p:not(:first-child) {
			color: #f44336;
		}
	}
	&--green {
		p:not(:first-child) {
			color: #03bc70;
		}
	}
}

.grey-text {
	color: $text-grey;
}

.table-header {
	.tr {
		border-bottom: 1px solid $border-color;
		align-items: flex-start;
	}

	.td {
		padding-top: 9px;
		padding-bottom: 9px;
		word-break: normal;
	}
}

.table-body {
	.tr {
		border-bottom: 1px solid $border-color;
		&:last-child,
		&:only-child {
			border: none;
		}
	}
}

.td-name {
	font-weight: 500;
	font-size: 12px;
	&--action {
		text-align: right;
	}
	&--status {
		display: flex;
		justify-content: flex-start;
	}
}

.table-buttons {
  display: flex;
  justify-content: flex-end;
  button {
    flex: 0 0 15px;
    max-width: 15px;
    .stroke path {
      stroke: var(--grey);
    }
    &.lock {
      .stroke path {
        stroke: #f44336;
      }
    }
    &.active {
      .stroke path {
        stroke: var(--main-color);
      }
    }
    &.disable {
      display: none;
    }
    &:disabled {
      cursor: default;
      .edit-icon:before {
        color: var(--grey-color);
      }
    }
  }
  button + button {
    margin-left: 10px;
  }
  &--right {
    justify-content: flex-end;
  }
}

.td-hidden-name {
	display: none;
	font-weight: 500;
}

.td-title {
	display: block;
	font-size: 12px;
	line-height: 18px;
	color: rgba(37, 38, 45, 0.5);
}

.td-desc {
	font-size: 14px;
	&--grey {
		color: rgba(37, 38, 45, 0.5);
	}
}

.link {
	color: #599dc4;
	text-decoration: underline;
}

.admin {
	display: flex;
	align-items: center;
	margin: 0 0 0 auto;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	&__logout {
		display: flex;
		flex: 0 0 15px;
		max-width: 15px;
		margin-left: 14px;
		.stroke path {
			stroke: #fff;
		}
	}
}

.status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 11px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
  text-align: center;
  &--verified,
  &--success, &--completed {
    background: #00da83;
  }
  &--inprogres,
  &--pending,
  &--filled {
    background: #4A7A88; 
  }
  &--error,
  &--failed, &--cancelled, &--rejected{
    background: #f44336;
  }
  &--dark {
    background: #022a36;
  }
  &--close {
    background: #011e26;
  }
  &--under-dispute,
  &--unverified, &--expired {
    background: #71a2b0;
  }
  &--on-hold, &--received {
    background: #F3BA2F;
  }
  &--grey {
    background: #777E90;
  }
   
}

.pagination-block {
	display: flex;
	justify-content: flex-end;
	margin-top: 15px;
}

.pagination {
	display: flex;
	align-items: center;
	> li {
		> a,
		> button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			background: #fff;
			border: 1px solid #d2d2d2;
			border-radius: 4px;
			font-size: 14px;
			color: var(--font-color);
			transition: all 0.15s ease;
			svg {
				width: 9px;
			}
			&.arrow {
				.stroke path {
					stroke: var(--main-color);
				}
			}
			&.disabled {
				cursor: default;
				.stroke path {
					stroke: #9ea2a9;
				}
			}
			&.active {
				border-color: var(--main-color);
				color: var(--main-color);
			}
			&:hover {
				color: var(--main-color);
			}
		}
		& + li {
			margin-left: 10px;
		}
	}
}

.breadcrumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	&__item {
		margin-right: 15px;
		opacity: 0.5;
		color: #000;
		font-size: 12px;
		font-weight: 400;
		&:last-child {
			opacity: 1;
		}
	}
	&__icon {
		display: flex;
		width: 9px;
		margin-right: 15px;
		opacity: 0.5;
		.stroke path {
			stroke: #000;
		}
	}
}

.panel {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 22px;
	&--margin-none {
		margin-top: 0;
	}
}

.user-block {
	display: flex;
	align-items: center;
	&__name {
		margin-right: 15px;
		font-size: 29px;
	}
	&__id {
		position: relative;
		top: 2px;
		font-size: 22px;
		color: $grey;
	}

	.status {
		padding: 4px 10px;
	}
}

.back-btn {
	display: flex;
	flex: 0 0 22px;
	width: 22px;
	max-width: 22px;
	margin-right: 12px;
	.fill path {
		fill: #000;
	}
	&--small {
		width: 10px;
		max-width: 10px;
		flex: 0 0 10px;
	}
}

.filters {
	display: flex;
	margin-left: auto;
}

.filter {
	width: 130px;
	margin-right: 16px;
	&:last-child {
		margin: 0;
	}
}

.content-block {
	margin-top: 22px;
	background: #ffffff;
	border: 1px solid $border-color;
	border-radius: 10px;
	//box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
	//0px 0.5px 2px rgba(96, 97, 112, 0.16);
	&__inside {
		padding: 25px 30px;
		&--bt {
			border-top: 1px solid #e3e7ee;
		}
	}

	&--walletman {
		margin-top: 0;
		border-radius: 0 10px 10px 10px;
	}

	&--nomt,
	&--mt-0 {
		margin-top: 0;
	}
}

.forms-block {
	display: flex;
	flex-wrap: wrap;
	padding-top: 8px;
}

.block-title {
	font-size: 16px;
	font-weight: 500;
	&--big {
		font-size: 24px;
	}
}

.user-transaction {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.user-info {
	display: flex;
	flex-wrap: wrap;
	&__block {
		&:not(:last-child) {
			margin-right: 63px;
		}
	}
}

.personal-info {
	&__name {
		display: block;
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
	}
	&__desc {
		display: flex;
		align-items: flex-end;
		&:hover {
			.personal-info__text {
				text-decoration: underline;
			}
		}
	}
	&__text {
		display: block;
		font-size: 16px;
		line-height: 18px;
		padding-right: 8px;
	}
	&__edit {
		width: 30px;
		height: 30px;
		background: #27ae60;
		border-radius: 3px;
		padding: 0;
		min-width: 30px;
		&:hover {
			background: #1b9850;
		}
	}
}

.operation {
	display: flex;
	align-items: center;
	&__status {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 3px;
		background-color: #27ae60;
		width: 30px;
		height: 30px;
	}
	&__status-icon {
		display: flex;
		align-items: center;
	}
	&__info {
		padding-left: 8px;
		flex: 1;
	}
	&__title {
		display: block;
		font-size: 14px;
		line-height: 18px;
	}
	&__desc {
		display: block;
		padding-top: 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #9ea2a9;
	}
}

.level {
	display: flex;
	align-items: center;
	margin-left: auto;
	&__title {
		display: block;
		padding-right: 24px;
		font-weight: bold;
		font-size: 18px;
		line-height: 160%;
	}
	.select--small .select__drop {
		min-width: 100%;
	}
}

.stats {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	&__header {
		display: flex;
		align-items: baseline;
	}
	&__title {
		display: block;
		font-weight: bold;
		font-size: 18px;
		line-height: 16px;
		padding-right: 16px;
	}
	&__desc {
		display: block;
		font-size: 14px;
		line-height: 16px;
	}
	&__info {
		max-width: 515px;
		margin-right: 30px;
		&--full {
			max-width: 100%;
		}
	}
	&__list {
		display: flex;
		gap: 16px;
		// align-items: center;
		flex-wrap: wrap;
		padding-top: 20px;
	}
}

.stats-item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 12px 25px 12px 12px;
	width: 48%;
	height: 44px;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
		0px 0px 1px rgba(0, 0, 0, 0.04);
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.3s ease;
	&__header {
		display: flex;
		align-items: center;
		height: 20px;
		width: 100%;
		.stats-item__elem {
			margin-left: auto;
		}
	}
	&--small {
		flex-direction: column;
		width: 120px;
		align-items: flex-start;
		.stats-item__desc {
			padding-top: 5px;
		}
	}
	&--active {
		background-color: var(--main-color);
		color: #fff;
		height: auto;
		transition: all 0.3s ease;
		.stats-item__desc {
			display: block;
		}
		.stats-item__arrow {
			transform: rotate(90deg);
			span:before {
				color: #fff;
			}
		}
	}
	&__arrow {
		position: absolute;
		top: 6px;
		right: 10px;
		transform: rotate(-90deg);
		span:before {
			font-size: 8px;
			color: #011e26;
		}
	}
	&__name {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
	&__img {
		display: block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		// background: #C4C4C4;
		margin-right: 6px;
		img {
			position: relative;
			top: -2px;
		}
	}
	&__title {
		display: block;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.4;
	}
	&__desc {
		display: none;
		width: 100%;
		padding-left: 20px;
		margin-top: 8px;
	}
	&__elem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.4;
		word-break: break-all;
		& + .stats-item__elem {
			margin-top: 4px;
		}
	}
}

.chart {
	margin-right: 70px;
}

.forms-row {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.forms-block {
	display: flex;
	flex-wrap: wrap;
	padding-top: 8px;
	&--big {
		max-width: 674px;
		flex: 1;
		.form {
			width: 100%;
			padding: 10px 57px 30px 30px;
		}
		.input__name {
			font-size: 20px;
		}
	}
}

.trade-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	padding: 15px;
	margin: 0 auto;
}

.form {
	margin-top: 22px;
	width: 355px;
	max-width: 100%;
	background: #ffffff;
	border: 1px solid #e3e7ee;
	border-radius: 4px;
	padding: 30px;
	&--settings {
		width: 352px;
	}
	&--second-type {
		display: flex;
		align-items: flex-end;
		width: 100%;
		padding: 0;
		border: none;
		margin-top: 11px;
		.form__content {
			flex: 1;
			padding-right: 34px;
			margin-top: 0;
		}
		.input {
			margin-bottom: 0;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
	&__content {
		margin-top: 20px;
	}
}

.radio-row {
	display: flex;
	gap: 20px;
	&--mb-16 {
		margin-bottom: 16px;
	}
}

.table-footer {
	display: flex;
	justify-content: flex-end;
	padding-top: 20px;
}

.section-block {
	padding-top: 30px;
}

.info-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	&:hover {
		.info-dropdown__title {
			color: var(--purple);
		}
		.info-dropdown__menu {
			display: block;
		}
	}
	&__menu {
		display: none;
		position: absolute;
		top: 100%;
		left: 83%;
		padding-top: 6px;
		z-index: 1;
	}
	&__list {
		background: #f6f6f6;
		border: 1px solid #e3e7ee;
		padding: 4px 0;
		border-radius: 4px;
	}
	&__item {
		display: block;
		padding: 6px 11px;
		font-size: 14px;
		line-height: 18px;
		white-space: nowrap;
		&:not(:last-child) {
			box-shadow: inset 0px -1px 0px $border-color;
		}
	}
}

.info-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	margin-left: 6px;
}

.reports {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	padding-top: 30px;
	&__item {
		&:not(:last-child) {
			margin-right: 16px;
		}
	}
}

.report-item {
	background: #ffffff;
	border: 1px solid #e3e7ee;
	border-radius: 4px;
	padding: 28px 32px;
	width: 352px;
	&__status {
		display: inline-block;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		padding: 4px 10px;
		background: #ffd8d8;
		border-radius: 11px;
		color: #e46d6d;
	}
	&__img {
		width: 80px;
		height: 80px;
		border-radius: 4px;
		overflow: hidden;
	}
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 16px;
	}

	&__title {
		display: flex;
		align-items: center;
	}
	&__icon {
		display: flex;
		align-items: center;
		width: 18px;
		height: 15px;
	}

	&__text {
		display: block;
		padding-left: 12px;
		font-size: 18px;
		line-height: 18px;
	}
	&__info {
		padding-right: 20px;
	}
	&__price {
		display: block;
		padding-top: 28px;
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
	}
	&__footer {
		padding-top: 28px;
	}
}

.connect-data {
	max-width: 720px;
	background: #ffffff;
	border: 1px solid #e3e7ee;
	border-radius: 4px;
	padding: 22px 32px 36px 32px;
	&--margin-top {
		margin-top: 16px;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__title {
		display: block;
		font-size: 18px;
		line-height: 18px;
		color: #5bc044;
	}
	&__send {
		height: 48px;
	}
}

.close-btn {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #e3e7ee;
	border-radius: 50%;
	&__icon {
		display: flex;
		width: 10px;
	}
	&:hover {
		background-color: #e3e7ee;
	}
}

@import 'admin__media.scss';
